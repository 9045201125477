<template>
	<div v-if="!showCommentDetail && !showEvaluateDetail">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="用户昵称" name="nickname" class="ui-form__item">
						<a-input v-model:value="formState.nickname" placeholder="请输入用户昵称"></a-input>
					</a-form-item>

					<a-form-item label="用户手机号" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机"></a-input>
					</a-form-item>

					<a-form-item label="所属影片/演出名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入所属影片/演出名称"></a-input>
					</a-form-item>

					<a-form-item label="内容" name="comment" class="ui-form__item">
						<a-input v-model:value="formState.comment" placeholder="请输入内容"></a-input>
					</a-form-item>

					<a-form-item label="戏剧类型" class="ui-form__item" name="type">
						<a-select v-model:value="formState.type" placeholder="请选择戏剧类型" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">演出</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="评价类型" class="ui-form__item" name="typeCondition">
						<a-select v-model:value="formState.typeCondition" placeholder="请选择评价类型" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">精选</a-select-option>
							<a-select-option :value="2">有图</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="是否展示" class="ui-form__item" name="isBottom">
						<a-select v-model:value="formState.isBottom" placeholder="请选择是否展示" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="评价日期" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<div v-if="name" style="font-size: 18px;font-weight: 500;">影片/演出：{{ name }}</div>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 1450 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.nickname || '-' }}</div>
							<div>用户手机号：{{ record.phone || '-' }}</div>
						</template>
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 1">影片</div>
							<div v-if="record.type === 2">演出</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'isBottom'">
							{{ record.isBottom ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'content'">
							<div v-if="record.imageUrl">
								<a-image v-for="item in record.imageUrl.split(',')" :key="item" :src="item" style="width: 80px;margin: 0 4px 4px 0;"></a-image>
							</div>
							<a-tooltip placement="topLeft">
							    <template #title>
							          <span>{{ record.comment }}</span>
							    </template>
								<div ref="content" class="ui-from__content">{{ record.comment }}</div>
							</a-tooltip>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<!-- <div><a-menu-item @click="toComment(record)">评价详情</a-menu-item></div> -->
										<div><a-menu-item @click="toEvaluate(record)">查看评论</a-menu-item></div>
										<div v-if="record.isChoice === 0"><a-menu-item @click="onChangeStatus(record, 'isChoice', 1)">设为精选</a-menu-item></div>
										<div v-else><a-menu-item @click="onChangeStatus(record, 'isChoice', 0)">取消精选</a-menu-item></div>
										<div v-if="record.isBottom === 0"><a-menu-item @click="onChangeStatus(record, 'isBottom', 1)">设为底部评价</a-menu-item></div>
										<div v-else><a-menu-item @click="onChangeStatus(record, 'isBottom', 0)">取消底部评价</a-menu-item></div>
										<div v-if="record.isDetailComment === 0"><a-menu-item @click="onChangeStatus(record, 'isDetailComment', 1)">设为滚动评价</a-menu-item></div>
										<div v-else><a-menu-item @click="onChangeStatus(record, 'isDetailComment', 0)">取消滚动评价</a-menu-item></div>
										<div><a-menu-item @click="onDelete(record)">删除</a-menu-item></div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal title="详情" v-model:visible="editModalVisible" @ok="onEditOk">
				<a-spin :spinning="loading">
					<div class="detail">
						<div>
							<h3 class="ui-title">用户信息</h3>
							<a-descriptions style="margin-top: 30px">
								<a-descriptions-item label="用户名称">{{ userInfo.name }}</a-descriptions-item>
								<a-descriptions-item label="日期">{{ userInfo.time }}</a-descriptions-item>
								<a-descriptions-item label="评分">{{ userInfo.score }}</a-descriptions-item>
							</a-descriptions>
						</div>
						<div>
							<h3 class="ui-title">评价内容</h3>
							<a-textarea style="margin-top: 20px;" v-model:value="content"></a-textarea>
						</div>
					</div>
				</a-spin>
			</a-modal>

		</a-spin>

	</div>
	<evaluateDetail v-if="showEvaluateDetail" :id="id" @back="onBackEvaluateDetail"></evaluateDetail>
	<commentDetail v-if="showCommentDetail" @back="onBackCommentDetail"></commentDetail>
</template>

<script>
	import commentDetail from "./commentDetail/commentDetail.vue";
	import evaluateDetail from "./evaluateDetail/evaluateDetail.vue";
	import { getCommentList, deleteComment, changeCommentStatus } from '@/service/modules/comment.js';
	import {
		Icon
	} from '@/components/icon/icon.js';
	export default {
		components: {
			Icon,
			commentDetail,
			evaluateDetail
		},
		data() {
			return {
				filmId: 0,
				playId: 0,
				name: '',
				showEvaluateDetail: false,
				showCommentDetail: false,
				filmName: '戏剧名称',
				content: "啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬",
				userInfo: {
					name: "用户名称",
					score: "9分",
				},
				isEdit: false,
				editModalVisible: false,
				loading: false,
				id: 0,
				time: [],
				searchData: {},
				formState: {},
				columns: [{
					title: '用户信息',
					key: 'userInfo'
				}, {
					title: '戏剧类型',
					key: 'type',
					width: 120
				}, {
					title: '所属影片/演出',
					dataIndex: 'name',
				}, {
					title: '评价类型',
					dataIndex: 'include',
					width: 120
				}, {
					title: '评价日期',
					key: 'createTime',
					width: 180
				}, {
					title: '评分',
					dataIndex: 'score',
					width: 120
				}, {
					title: '是否展示',
					key: 'isDetailComment',
					width: 100
				}, {
					title: '内容',
					key: 'content',
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			if (this.$route.query) {
				this.playId = this.$route.query.id;
				this.name = this.$route.query.name;
				this.type = this.$route.query.type;
			}
			this.getData();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getCommentList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					businessId: this.playId || undefined,
					type: this.type,
					name: this.name,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await deleteComment({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('删除成功');
							this.getData();
						}
					}
				})
			},
			onChangeStatus(item, type, status) {
				this.$confirm({
					title: '提示',
					content: '确定修改吗？',
					onOk: async ()=> {
						this.loading = true;
						let ret = await changeCommentStatus({
							id: item.id,
							type: type,
							status: status
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('修改成功');
							this.getData();
						}
					}
				})
			},
			onEditOk() {
				console.log("编辑完成");
			},
			onEdit(str) {
				this.isEdit = true;
				this.editModalVisible = true;
				this.content = str;
			},
			onBackEvaluateDetail(isRef) {
				this.showEvaluateDetail = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onBackCommentDetail(isRef) {
				this.showCommentDetail = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			toEvaluate(item) {
				this.id = item.id;
				this.showEvaluateDetail = true;
			},
			toComment(item) {
				this.showCommentDetail = true;
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-from__content {
		width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* 控制行数 */
		-webkit-box-orient: vertical;
	}

	.ui-form__label {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		color: #65bde3;
		cursor: pointer;
	}

	.ui-title {
		margin: 20px 0 10px;
		padding-left: 10px;
		font-size: 16px;
		font-weight: 600;
		position: relative;
	}

	.ui-title::before {
		content: "";
		width: 4px;
		height: 20px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #1890ff;
		border-radius: 2px;
	}
</style>